import React from 'react';
import { Error } from '../Error';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super();
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Error hasError={this.state.hasError} />;
        }

        return this.props.children;
    }
}

export { ErrorBoundary };
